var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticStyle:{"margin":"0px !important"},attrs:{"row":""}},[(!_vm.isConnected && !_vm.whatsappRegistered)?_c('v-flex',{staticStyle:{"padding-bottom":"1%","padding-top":"1%"},attrs:{"xs12":"","sm12":"","md12":""}},[_c('WhatsappConnector',{attrs:{"is-connected":_vm.isConnected,"whatsapp-registered":_vm.whatsappRegistered,"set-chat-show":_vm.setChatShow}})],1):_vm._e(),(!_vm.isConnected && !_vm.whatsappRegistered && !_vm.firstTimeTemplate)?_c('v-container',{staticClass:"whiteBackGround",attrs:{"fluid":""}},[_c('first-page',{attrs:{"img-src":_vm.imgSrc,"dialog-name":_vm.firstTimeTemplate,"points":_vm.points,"headline":_vm.headline},on:{"update:dialogName":function($event){_vm.firstTimeTemplate=$event},"update:dialog-name":function($event){_vm.firstTimeTemplate=$event}}})],1):(_vm.isConnected && _vm.whatsappRegistered)?_c('v-flex',{staticStyle:{"padding":"5px"},attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-card',{staticClass:"rounded"},[_c('v-card-title',[_vm._v(" Templates "),_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primarygrad","label":"","to":{
                path: "/whatsapp-templates/add",
                query: { page: 'Whatsapp' },
              }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add ")]),_vm._v(" Add a Template ")],1)],1)],1),_c('v-card-text',[(_vm.preloader)?_c('div',{staticStyle:{"text-align":"center","padding":"25px"},attrs:{"align":"center"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Please Wait ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):(_vm.templates)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":_vm.templates.length,"item-key":"id","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.headerFormat",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.components.some(function (item) { return item.type === "HEADER"; }) ? item.components.find(function (item) { return item.type === "HEADER"; }) .format : "")+" ")]}},{key:"item.header",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.components.some(function (item) { return item.type === "HEADER"; }) ? item.components.find(function (item) { return item.type === "HEADER"; }) .format === "TEXT" ? item.components.find(function (item) { return item.type === "HEADER"; }) .text : item.components.find(function (item) { return item.type === "HEADER"; }) .format : "")+" ")]}},{key:"item.body",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.components.find(function (item) { return item.type === "BODY"; }).text)+" ")]}},{key:"item.footer",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.components.some(function (item) { return item.type === "FOOTER"; }) ? item.components.find(function (item) { return item.type === "FOOTER"; }) .text : "")+" ")]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}}],null,true)})],1):_vm._e()])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }