<template>
  <v-layout>
    <v-card width="100%">
      <v-card-title>
        <v-icon large>mdi-facebook</v-icon>
        <span class="title" style="padding-left: 5px">Connect Facebook Business</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-layout row wrap style="margin: 0px">
            <v-flex xs12 sm12 md12 style="padding: 0%">
              <v-container justify="center">
                <h6 class="title font-weight-light" style="margin-top: 5px; text-align: center">
                  <!-- <h5 class="title font-weight-light">
                          {{ Math.round(addStorage / 10) }}GB
                        </h5> -->
                  Please connect with Facebook Business
                </h6>
                <v-layout row wrap style="padding-bottom: 4%;">
                  <v-flex xs12 sm12 m12> </v-flex>
                </v-layout>

                <v-layout style="justify-content: center">
                  <v-btn color="green" style="color: white" @click="launcwpSignup">
                    <v-icon class="mr-3">mdi-facebook</v-icon>
                    Connect Facebook Business
                  </v-btn>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
    <!-- Phone number asking popup -->
    <v-dialog v-model="openModal" width="50%" max-width="600px" persistant>
      <v-card class="pa-3" prepend-icon="mdi-update">
        <v-card-title>Kindly Choose Your Phone Number</v-card-title>
        <v-select v-model="selectedNumber" :items="phoneNumbers" item-text="display_phone_number"
          item-value="display_phone_number" return-object label="Numbers"></v-select>

        <v-card-title>Kindly Choose Your Default Whatsapp List</v-card-title>
        <v-select v-model="selectedList" :items="lists" item-text="name" item-value="id" label="List"></v-select>
        <v-card-actions>
          <v-btn class="ms-auto" @click="submitNumber">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {
    isConnected: {
      type: Boolean,
    },
    whatsappRegistered: {
      type: Boolean,
    },
    setChatShow: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      openModal: false,
      phoneNumbers: [],
      lists: [],
      accessToken: "",
      wabaDetails: {},
      selectedNumber: {},
      loader: false,
      selectedList: "",
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "teams",
      "companyDetails",
      "selectedTeam",
    ]),
  },
  async created() {
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );
    this.fetchLists();
  },
  methods: {
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];
        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;
        if (this.lists.length < 1) {
          return;
        }
        this.selectedList = this.lists[0].id;
      } catch (e) {
        console.log("error in list fetch", e);
      }
    },
    async launcwpSignup() {
      this.loader = true;

      var response = await this.$http.post(
        `${this.ENDPOINT}/whatsapps/send-email`
      );

      console.log("respose of launcwpSignup", response);

      // Launch Facebook login
      FB.login(
        async (response) => {
          console.log("FB response console", response);

          if (response.authResponse) {
            const code = response.authResponse.code;
            // For production use this app id and secret
            const appId = "740864193492825";
            const appSecret = "0a3e3710cd5068234f03e3c31d2120e2";
            // For testing use this app id and secret
            // const appId = "412356441821229";
            // const appSecret = "073f48932e6c05b19d357ddfda97b9eb";
            // Use this token to call the debug_token API and get the shared WABA's ID

            console.log("code after fb response", code);

            if (code) {
              const getAccessToken = await axios.get(
                `https://graph.facebook.com/v18.0/oauth/access_token?client_id=${appId}&client_secret=${appSecret}&code=${code}`
              );

              console.log("getAccessToken after code", getAccessToken);

              const data = {
                accessToken: getAccessToken.data.access_token,
              };

              const getBusinessDetails = await this.$http.post(
                `${this.ENDPOINT}/whatsapps/getWabaDetails`,
                data
              );

              console.log("getBusinessDetails", getBusinessDetails);

              var fbAccessToken = "";

              if (typeof getAccessToken.data != "undefined") {
                fbAccessToken = getAccessToken.data.access_token;
              } else {
                fbAccessToken = getAccessToken.body.access_token;
              }

              this.phoneNumbers = getBusinessDetails.body.numbers;
              this.accessToken = fbAccessToken;
              this.wabaDetails = getBusinessDetails.body;

              this.openModal = true;
              this.loader = false;
            }
          } else {
            console.log("User cancelled login or did not fully authorize.");
            this.loader = false;
          }
        },
        {
          config_id: "1945608942539641", // for production
          // config_id: "1975738559533552", // for test
          response_type: "code",
          override_default_response_type: true,
          extras: {
            setup: {
              // Prefilled data can go here
            },
          },
        }
      );
      // } else {
      //     this.isConnected = true;
      //     this.userDetails = response.authResponse;
      //     //Check if phone and business id is available in account
      //     // const getUser = await axios.get(${this.ENDPOINT}/accounts/${this.user.id}?access_token=${this.user.token});
      //     // if (getUser.data.whatsappBusinessId || getUser.data.whatsappNoId) {
      //     //     this.whatsappRegistered = true;
      //     // }
      //     const getIntegration = await axios.get(
      //         ${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp&access_token=${this.user.token}
      //     );
      //     console.log("Integration details: ", getIntegration.data);
      //     if (
      //         getIntegration.data.length &&
      //         (getIntegration.data[0].config.whatsappBusinessId ||
      //             getIntegration.data[0].config.whatsappNoId)
      //     ) {
      //         this.whatsappRegistered = true;
      //     }
      //     console.log("User: ", response);
      //     console.log("isConnected", this.isConnected);
      //     console.log("user already connected");
      //     console.log("this.whatsappRegistered", this.whatsappRegistered);
      // }
    },

    async submitNumber() {
      this.loader = true;

      console.log("this.selectednumber.is", this.selectedNumber);

      if (!this.selectedNumber.id) {
        this.$swal({
          type: "error",
          text:
            "Failed to create account! Whatsapp phone number id is not found",
        });
        return;
      }
      const data = {
        type: "whatsapp",
        enabled: true,
        config: {
          whatsappNoId: this.selectedNumber.id,
          whatsappBusinessId: this.wabaDetails.wabaId,
          whatsappMessageTemplates: ["hello_world"],
          tempAccessToken: this.accessToken,
          tokenGenerateTime: new Date(),
          displayPhoneNumber: this.selectedNumber.display_phone_number,
          verifiedName: this.selectedNumber.verified_name,
          initializeDone: false,
          whatsappListId: this.selectedList,
        },
        companyId: this.user.companyId,
      };
      try {
        const getIntegration = await this.$http.get(
          `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
        );

        console.log("getIntegration.body", getIntegration.body);
        // If data existed
        if (getIntegration.body.length > 0 && getIntegration.body != null) {
          if (getIntegration.body[0].config.initializeDone) {
            this.setChatShow();
            this.openModal = false;
            return;
          } else {
            // It will try to initialize againß
            // const initializeResp = await this.$http.post(
            //   ${this.ENDPOINT}/whatsapps/initialize
            // );

            // if (
            //   initializeResp.data.registerNumberResponse.success &&
            //   initializeResp.data.subscribeWebhookResponse.success
            // ) {
            //   // Updating data to add initialization status
            //   const updatedData = {
            //     config: {
            //       ...data.config,
            //       initializeDone: true,
            //     },
            //   };
            //   const updateIntegration = await this.$http.patch(
            //     ${this.ENDPOINT}/integrations/${getIntegration.data[0].id},
            //     updatedData
            //   );
            // }
            // Delete Integration
            const deleteIntegration = await this.$http.delete(
              `${this.ENDPOINT}/integrations/${getIntegration.body[0].id}`
            );

            console.log("existing integration deleted: ", deleteIntegration);
            // Register user again
            await this.registerUser(data);
            this.setChatShow();
            this.openModal = false;
          }
        } else {
          // If not

          // Creating a new integration for whatsapp
          // const resp = await this.$http.post(
          //   ${this.ENDPOINT}/integrations,
          //   data
          // );

          // if (resp.data) {
          //   const initializeResp = await this.$http.post(
          //     ${this.ENDPOINT}/whatsapps/initialize
          //   );

          //   if (
          //     initializeResp.data.registerNumberResponse.success &&
          //     initializeResp.data.subscribeWebhookResponse.success
          //   ) {
          //     // Updating data to add initialization status
          //     const updatedData = {
          //       config: {
          //         ...data.config,
          //         initializeDone: true,
          //       },
          //     };

          //     const updateIntegration = await this.$http.patch(
          //       ${this.ENDPOINT}/integrations/${resp.data.id},
          //       updatedData
          //     );
          //   }
          await this.registerUser(data);
          this.setChatShow();
          this.openModal = false;
          // }
        }
        this.loader = false;
      } catch (error) {
        console.log("Error: ", error);
        this.openModal = false;
        this.loader = false;
        this.$swal({
          type: "error",
          text: "An error occurred. Please try again!",
        });
      }
    },

    // async fetchWABusinessDetails(accessToken, wabaId) {
    //   try {
    //     // Fetch WhatsApp Business Account phone numbers using WABA ID
    //     const wabaPhoneNumbers = await axios.get(
    //       `https://graph.facebook.com/v11.0/${wabaId}/phone_numbers?access_token=${accessToken}`
    //     );

    //     // Fetch message templates for the WhatsApp Business Account
    //     const wabaTemplates = await axios.get(
    //       `https://graph.facebook.com/v11.0/${wabaId}/message_templates?access_token=${accessToken}`
    //     );

    //     // Prepare the data (customize this according to your backend needs)
    //     const wabaDetails = {
    //       wabaId: wabaId,
    //       phoneNumbers: wabaPhoneNumbers.data.data, // List of phone numbers
    //       templates: wabaTemplates.data.data, // List of message templates
    //     };

    //     // Send the WhatsApp Business Account details to your backend
    //     await axios.post(
    //       "YOUR_BACKEND_ENDPOINT/whatsappBusinessDetails",
    //       wabaDetails
    //     );
    //   } catch (error) {
    //     console.error("Failed to fetch WABA details or send to backend", error);
    //   }
    // },
    // // Inside your Vue component
    // async fetchFacebookUserData(accessToken) {
    //   try {
    //     // Existing code to fetch user info

    //     // Fetch associated Facebook Pages
    //     const pagesResponse = await axios.get(
    //       `https://graph.facebook.com/me/accounts?access_token=${accessToken}`
    //     );

    //     // Assuming you want details from the first page (you may need logic to choose the correct one)
    //     const pageId = pagesResponse.data.data[0].id;
    //     const pageAccessToken = pagesResponse.data.data[0].access_token; // Page-specific access token

    //     // Fetch WhatsApp Business Account ID using the page ID
    //     const wabaResponse = await axios.get(
    //       `https://graph.facebook.com/v11.0/${pageId}/whatsapp_business_accounts?access_token=${pageAccessToken}`
    //     );
    //     const wabaId = wabaResponse.data.data[0].id; // WhatsApp Business Account ID

    //     // Fetch WhatsApp Business Account details, such as phone numbers
    //     const wabaDetails = await axios.get(
    //       `https://graph.facebook.com/v11.0/${wabaId}/phone_numbers?access_token=${pageAccessToken}`
    //     );

    //     // Fetch message templates
    //     const templatesResponse = await axios.get(
    //       `https://graph.facebook.com/v11.0/${wabaId}/message_templates?access_token=${pageAccessToken}`
    //     );

    //     // Send user and WBA info to your backend (adjust according to your needs)
    //     await axios.post("YOUR_BACKEND_ENDPOINT/integrations", {
    //       type: "facebook",
    //       enabled: true,
    //       config: {
    //         userInfo: userInfo.data,
    //         wabaDetails: wabaDetails.data,
    //         templates: templatesResponse.data.data, // Array of message templates
    //       },
    //     });
    //   } catch (error) {
    //     console.error("Failed to fetch WBA data or send to backend", error);
    //   }
    // },

    async registerUser(data) {
      try {
        // Creating a new integration for whatsapp
        const resp = await this.$http.post(
          `${this.ENDPOINT}/integrations`,
          data
        );

        if (resp.body) {
          const initializeResp = await this.$http.post(
            `${this.ENDPOINT}/whatsapps/initialize`
          );

          console.log("initializeResp.body", initializeResp.body);

          if (
            initializeResp.body.registerNumberResponse.success &&
            initializeResp.body.subscribeWebhookResponse.success
          ) {
            // Updating data to add initialization status
            const updatedData = {
              config: {
                ...data.config,
                initializeDone: true,
              },
            };

            const updateIntegration = await this.$http.patch(
              `${this.ENDPOINT}/integrations/${resp.data.id}`,
              updatedData
            );

            console.log("updateIntegration: ", updateIntegration.body);
            return updateIntegration.body;
          } else {
            this.$swal({
              type: "error",
              text:
                "An error occured while registering user with meta. Please try again",
            });
            return;
          }
        }
      } catch (error) {
        return error;
      }
    },

  },
};
</script>
