<template>
  <v-layout fill-height align-center justify-center>
    <v-flex xs12 sm10 md9>
      <v-card flat tile>
        <div
          :class="
            isMobile()
              ? 'd-flex flex-column align-center justify-center pa-5'
              : 'd-flex align-center justify-space-between pa-5'
          "
        >
          <!-- Conditional Rendering for Mobile: Image at the Top -->
          <div v-if="isMobile()" class="image-container">
            <img :src="imgSrc" alt="landing image" />
          </div>

          <!-- Left Side Content -->
          <div>
            <h2>{{ headline }}</h2>
            <div class="pt-10">
              <div
                v-for="(item, index) in points"
                :key="index"
                class="d-flex align-center mb-2 pr-10"
              >
                <div class="mr-2 cs-box">
                  <img
                    src="../assets/arrow.svg"
                    alt="arrow"
                    style="margin-top: -12px;"
                  />
                </div>
                <p class="custom-margin">{{ item }}</p>
              </div>
            </div>
            <!-- Buttons -->
            <div v-if="typeof btnUrl == 'undefined'" class="mt-3">
              <v-btn
                color="#00CF46"
                class="white--text mr-3 text-transform"
                @click="$emit('update:dialogName', true)"
              >
                Get Started
              </v-btn>
            </div>

            <div v-else class="mt-3">
              <v-btn
                color="#00CF46"
                class="white--text mr-3 text-transform"
                @click="
                  $router.push({
                    path: btnUrl,
                  })
                "
              >
                Get Started
              </v-btn>
            </div>
          </div>

          <!-- Right Side Image for Desktop -->
          <div v-if="!isMobile()" class="image-container">
            <img :src="imgSrc" alt="landing image" style="min-height: 400px;" />
          </div>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    dialogName: {
      type: Boolean,
      required: false,
    },
    points: {
      type: Array,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    btnUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam"]),
  },
  async mounted() {
    console.log("btnURL", this.btnUrl);
  },
  async created() {},
  methods: {
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.cs-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: auto;
  /* Change this to auto to maintain aspect ratio */
  max-width: 100%;
  /* Prevents the image from exceeding the container width */
  height: auto;
}

.text-transform {
  text-transform: capitalize;
}

.whiteBackGround {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-margin {
  margin: 0;
  /* Override any default margin */
}

@media (max-width: 760px) {
  .image-container {
    margin-bottom: 20px;
    /* Adds spacing between the image and text content on mobile */
  }
}

.customRadiusDialog {
  border-radius: 0px !important;
}

.v-application .color-avatar {
  cursor: pointer;
  border: 4px solid transparent;
  /* Maintains layout consistency */
  border-radius: 50%;
  /* Ensures the avatar is round */
  transition: border-color 0.3s;
}

.v-application .active-border {
  border-color: rgb(188 188 188) !important;
  /* Overrides Vuetify theme color */
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-simple-table td {
  vertical-align: middle;
}

.v-img {
  max-width: 50px;
  height: auto;
  display: inline-block;
}

.green-row {
  background-color: #cffede;
  /* Light green color */
}
</style>
