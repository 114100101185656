<template>
  <v-container fluid>
    <!-- <v-container
      v-if="
        !isConnected &&
          !whatsappRegistered &&
          !firstTimeTemplate &&
          templates.length <= 0
      "
      fluid
      class="whiteBackGround"
    >
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="firstTimeTemplate"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-container> -->

    <v-layout row style="margin: 0px !important">
      <v-flex
        v-if="!isConnected && !whatsappRegistered"
        xs12
        sm12
        md12
        style="padding-bottom: 1%;padding-top:1%"
      >
        <WhatsappConnector
          :is-connected="isConnected"
          :whatsapp-registered="whatsappRegistered"
          :set-chat-show="setChatShow"
        />
      </v-flex>

      <v-container
        v-if="!isConnected && !whatsappRegistered && !firstTimeTemplate"
        fluid
        class="whiteBackGround"
      >
        <first-page
          :img-src="imgSrc"
          :dialog-name.sync="firstTimeTemplate"
          :points="points"
          :headline="headline"
        ></first-page>
      </v-container>

      <v-flex
        v-else-if="isConnected && whatsappRegistered"
        xs12
        sm12
        md12
        style="padding: 5px"
      >
        <v-card class="rounded">
          <v-card-title>
            Templates
            <v-spacer></v-spacer>
            <div>
              <v-btn
                class="ma-2"
                dark
                color="primarygrad"
                label
                :to="{
                  path: `/whatsapp-templates/add`,
                  query: { page: 'Whatsapp' },
                }"
              >
                <v-icon left>
                  add
                </v-icon>
                Add a Template
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <div
              v-if="preloader"
              style="text-align: center; padding: 25px"
              align="center"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="subtitle-1 text-center" cols="12">
                  Please Wait
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="templates">
              <v-data-table
                :headers="headers"
                :items="templates"
                :items-per-page="templates.length"
                item-key="id"
                hide-default-footer
                disable-sort
              >
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:[`item.headerFormat`]="{ item }">
                  {{
                    item.components.some((item) => item.type === "HEADER")
                      ? item.components.find((item) => item.type === "HEADER")
                          .format
                      : ""
                  }}
                </template>
                <template v-slot:[`item.header`]="{ item }">
                  {{
                    item.components.some((item) => item.type === "HEADER")
                      ? item.components.find((item) => item.type === "HEADER")
                          .format === "TEXT"
                        ? item.components.find((item) => item.type === "HEADER")
                            .text
                        : item.components.find((item) => item.type === "HEADER")
                            .format
                      : ""
                  }}
                </template>
                <template v-slot:[`item.body`]="{ item }">
                  {{
                    item.components.find((item) => item.type === "BODY").text
                  }}
                </template>
                <template v-slot:[`item.footer`]="{ item }">
                  {{
                    item.components.some((item) => item.type === "FOOTER")
                      ? item.components.find((item) => item.type === "FOOTER")
                          .text
                      : ""
                  }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status }}
                </template>
              </v-data-table>
              <!-- <v-list two-line>
                  <v-list-item
                    v-for="(item, i) in templates"
                    :key="i"
                    :to="{
                      path: `/whatsapp-templates/${item.id}`,
                      query: { page: 'Whatsapp' },
                    }"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.desc"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
  
                    <v-list-item-action>
                      <v-btn icon color="blue" style="text-transform:none;">
                        {{ item.isLink || item.type == "link" ? "Link" : "" }}
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list> -->
            </div>
          </v-card-text>
          <!-- <v-card-text style="padding: 0px 10px">
                <div>
                  <v-list flat subheader three-line>
                    <v-subheader>Filter</v-subheader>
                    <v-list-item>
                      <template>
                        <v-list-item-action>
                          <v-checkbox
                            color="green"
                            @change="filterByLink($event)"
                          ></v-checkbox>
                        </v-list-item-action>
    
                        <v-list-item-content>
                          <v-list-item-title>Link</v-list-item-title>
                          <v-list-item-subtitle
                            >Check if the template is created as
                            link</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
  </v-list-item>
  </v-list>
  </div>
  </v-card-text> -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs.vue";
import WhatsappConnector from "../../components/whatsappConnector.vue";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    Breadcrumbs,
    WhatsappConnector,
    firstPage,
  },
  data() {
    return {
      firstTimeTemplate: false,
      noDialog: false,
      headline: "Build Your Own Template Library",
      points: [
        "Stop Wasting Time Typing: Create custom templates for common messages, freeing up your team to focus on personalized interactions",
        "Broadcast Like a Pro: Effortlessly send pre-designed templates to large groups, saving time and reaching more customers",
        "Maintain Brand Consistency: Ensure all your team's messages are clear, concise, and on-brand with a library of reusable templates.",
      ],
      isConnected: false,
      whatsappRegistered: false,
      templates: [],
      allTemplates: [],
      preloader: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Header Format", value: "headerFormat" },
        { text: "Header", value: "header" },
        { text: "Body", value: "body" },
        { text: "Footer", value: "footer" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "dispoList",
      "teams",
      "user",
      "selectedTeam",
      "selectedList",
    ]),
    imgSrc() {
      return require("@/assets/gotemplate.svg");
    },
  },
  async created() {
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );

    if (
      getIntegration.body.length &&
      getIntegration.body[0].config.initializeDone
    ) {
      this.setChatShow();
      this.getTemplates();
    }

    // this.getMessages();
  },
  methods: {
    setChatShow() {
      this.isConnected = true;
      this.whatsappRegistered = true;
      this.firstTimeTemplate = true;
    },
    filterByLink(active) {
      if (active) {
        this.templates = _.filter(this.allTemplates, (t) => {
          return t.isLink;
        });
        return;
      }

      this.templates = this.allTemplates;
    },

    getTemplates() {
      var self = this;
      this.preloader = true;
      this.templates = [];
      this.$http
        .get(this.$store.state.ENDPOINT + "/whatsapps/getTemplates")
        .then(
          (response) => {
            self.preloader = false;
            response = response.body;
            if (response && response.data && Array.isArray(response.data)) {
              this.templates = response.data;

              console.log("all templates: ", this.templates);

              // this.templates = _.filter(response.data, { status: "APPROVED" });
            } else {
              this.$swal({
                type: "error",
                text: "Invalid response format while loading the templates.",
              });
            }
            this.preloader = false;
          },
          (error) => {
            this.preloader = false;
            this.$swal({
              type: "error",
              text: "Something went wrong while loading the templates.",
            });
          }
        );
    },
  },
};
</script>

<style scoped></style>
